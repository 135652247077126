.filepond--root .filepond--credits[style] {
    top: 0;
    bottom: auto;
    margin-top: 14px;
    display: none;
}

.blockbuilder-branding {
    display: none !important;
}

.fTSskP .blockbuilder-branding {
    display: none !important;
    opacity: 0;
}

a.blockbuilder-branding {
    display: none !important;
    opacity: 0 !important ;
}
a[href="https://unlayer.com/?utm_medium=web-editor&amp;utm_campaign=editor-referral"]{
    display: none !important;
    opacity: 0 !important ;
}