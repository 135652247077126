.blockbuilder-branding {
    display: none !important;
}

.fTSskP .blockbuilder-branding {
    display: none !important;
    opacity: 0 !important;
}


a.blockbuilder-branding {
    display: none !important;
    opacity: 0 !important ;
}
a[href="https://unlayer.com/?utm_medium=web-editor&amp;utm_campaign=editor-referral"]{
    display: none !important;
    opacity: 0 !important ;
}
