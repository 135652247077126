
.filter-node {
    width: 4rem;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 4rem;
    color: #f2f2f2;
    vertical-align: middle;
    background: linear-gradient(#bbb, #777);
  }
  .filter-node .srd-port {
    border: 5px solid #de6262;
    /* background-color: #42ad6d; */
    background: rgb(255, 254, 254);
    top: 34%;
    position: absolute;
    width: 20px;
    height: 20px;
  }
  .filter-node .srd-port.true {
    top: 10%;
  }
  
  .filter-node .srd-port.false {
    top: 61%;
  }
  
  .filter-node .right-port .srd-port {
    right: -15px;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .filter-node .left-port .srd-port {
    left: -15px;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .filter-node .left-port {
    float: left;
    width: 15%;
  }
  .filter-node .scene {
    float: left;
    width: 70%;
    height: 4rem;
    padding-top: 26%;
    /* padding-left: 18px; */
    text-align: center;
    vertical-align: middle;
  }
  .filter-node .right-port {
    float: left;
    width: 15%;
  }
  
  
  .node.srd-node > .filter-node {
    border-color: #de6262;
    border-radius: 20%;
    background: #de6262;
  }
  .node.srd-node > .filter-node .label--desc {
    position: absolute;
    top: 4.5em;
    left: -1.8em;
    background: #f5365c;
    color: rgb(255, 255, 255);
    padding: 1px;
    border-radius: 10px;
    width: 8em;
    text-align: center;
  }
  .node.srd-node--selected > .filter-node .label--desc {
    position: absolute;
    z-index: 2;
    top: 4.5em;
    left: -1.8em;
    background: #f5365c;
    color: rgb(255, 255, 255);
    padding: 1px;
    border-radius: 10px;
    width: 8em;
    text-align: center;
  }

  .filter-node .label--desc--warning {
    position: absolute;
    top: 4.5em;
    left: -1.8em;
    background: #f5e236;
    color: rgb(34, 34, 34);
    padding: 1px;
    border-radius: 10px;
    width: 8em;
    text-align: center;
  }

  .node.srd-node > .filter-node .label--annotate {
    position: absolute;
    top: 5em;
    left: -3.8em;
    background: #fff;
    color: #666;
    /* color: rgb(255, 255, 255); */
    padding: 2px;
    border-radius: 10px;
    width: 12em;
    text-align: center;
  }
  .node.srd-node--selected > .filter-node .label--annotate {
    position: absolute;
    top: 5em;
    left: -3.8em;
    background: #fff;
    color: #666;
    /* color: rgb(255, 255, 255); */
    padding: 1px;
    border-radius: 10px;
    width: 12em;
    text-align: center;
  }
  .node.srd-node--selected > .filter-node .right-port .srd-port {
    border-color: #ffb88c;
  }
  .node.srd-node--selected > .filter-node .left-port .srd-port {
    border-color: #de6262;
  }
  
  .node.srd-node--selected > .filter-node {
    border-color: transparent;
    border: none;
    border-radius: 20%;
    background: linear-gradient(to right, #de6262, #ffb88c);
    -webkit-filter: drop-shadow(0 0 20px rgba(56, 190, 239, 0.632));
  }

  
  
  .rotating-dashed {
    position: absolute;
    margin: 40px auto;
    width: 90px;
    height: 90px;
    overflow: hidden;
    color: #3498db;
    margin: -13px;
  }
  .rotating-dashed:hover {
    color: #4caf50;
    cursor: pointer;
    transition: all 0.5s;
  }
  .rotating-dashed .dashing {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .rotating-dashed .dashing:nth-of-type(1) {
    transform: rotate(0deg);
  }
  .rotating-dashed .dashing:nth-of-type(2) {
    transform: rotate(90deg);
  }
  .rotating-dashed .dashing:nth-of-type(3) {
    transform: rotate(180deg);
  }
  .rotating-dashed .dashing:nth-of-type(4) {
    transform: rotate(270deg);
  }
  .rotating-dashed .dashing i {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    border-bottom: 5px dashed;
    animation: slideDash 2.5s infinite linear;
  }
  .rotating-dashed strong {
    display: block;
    width: 95px;
    line-height: 90px;
    text-align: center;
    position: absolute;
    font-size: 50px;
    transform: rotate(90deg);
  }
  
  @keyframes slideDash {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0%);
    }
  }
