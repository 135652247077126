
.l-bg-cherry {
    /* background: linear-gradient(to right, #493240, #f09) !important;
     */
     background: #ee3e74;
    color: #fff;
    border: none; 
     border-radius: 0.5rem;
}

.l-bg-blue-dark {
    /* background: linear-gradient(to right, #373b44, #4286f4) !important; */
    background: #f1d665;
    color: #fff;
    border: none; 
    border-radius: 0.5rem;
}

.l-bg-green-dark {
    /* background: linear-gradient(to right, #0a504a, #38ef7d) !important; */
    background: #8d5f9b;
    color: #fff;
    border: none; 
    border-radius: 0.5rem;
}

.l-bg-orange-dark {
    /* background: linear-gradient(to right, #a86008, #ffba56) !important; */
    background: #10d2ad;
    color: #fff;
    border: none; 
    border-radius: 0.5rem;
}

.card .card-statistic-3 .card-icon-large .fas, .card .card-statistic-3 .card-icon-large .far, .card .card-statistic-3 .card-icon-large .fab, .card .card-statistic-3 .card-icon-large .fal {
    font-size: 80px;
}

.card .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: rgb(255, 255, 255);
    position: absolute;
    right: 5px;
    top: 20px;
    opacity: 0.3;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
}

.l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
}

.l-bg-orange {
    background: linear-gradient(to right, #f9900e, #ffba56) !important;
    color: #fff;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
}