
.dataentry-node {
  width: 4rem;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 4rem;
  color: #f2f2f2;
  vertical-align: middle;
  background: linear-gradient(#bbb, #777);
}
.dataentry-node .srd-port {
  border: 5px solid #0096EF;
  /* background-color: #42ad6d; */
  background: rgb(255, 254, 254);
  top: 34%;
  position: absolute;
  width: 20px;
  height: 20px;
}
.dataentry-node .srd-port.top {
  top: 20%;
}

.dataentry-node .srd-port.bottom {
  top: 60%;
}

.dataentry-node .right-port .srd-port {
  right: -15px;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.dataentry-node .left-port .srd-port {
  left: -15px;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.dataentry-node .left-port {
  float: left;
  width: 15%;
}
.dataentry-node .scene {
  float: left;
  width: 70%;
  height: 4rem;
  padding-top: 26%;
  padding-left: 18px;
  text-align: center;
  vertical-align: middle;
}
.dataentry-node .right-port {
  float: left;
  width: 15%;
}


.node.srd-node > .dataentry-node {
  border-color: #0096EF;
  border-radius: 20%;
  background: #0096EF;
}
.node.srd-node > .dataentry-node .label--desc {
  position: absolute;
  top: 4.5em;
  left: -1.8em;
  background: #f5365c;
  color: rgb(255, 255, 255);
  padding: 1px;
  border-radius: 10px;
  width: 8em;
  text-align: center;
}
.node.srd-node--selected > .dataentry-node .label--desc {
  position: absolute;
  top: 4.5em;
  left: -1.8em;
  background: #f5365c;
  color: rgb(255, 255, 255);
  padding: 1px;
  border-radius: 10px;
  width: 8em;
  text-align: center;
}

.node.srd-node > .dataentry-node .label--annotate {
  position: absolute;
  top: 5em;
  left: -3.8em;
  background: #fff;
  color: #666;
  /* color: rgb(255, 255, 255); */
  padding: 2px;
  border-radius: 10px;
  width: 12em;
  text-align: center;
}
.node.srd-node--selected > .dataentry-node .label--annotate {
  position: absolute;
  top: 5em;
  left: -3.8em;
  background: #fff;
  color: #666;
  /* color: rgb(255, 255, 255); */
  padding: 1px;
  border-radius: 10px;
  width: 12em;
  text-align: center;
}
.node.srd-node--selected > .dataentry-node .left-port .srd-port {
  border-color: #0096EF;
}
.node.srd-node--selected > .dataentry-node .right-port .srd-port {
  border-color: #4caf50;
}

.node.srd-node--selected > .dataentry-node {
  border-color: transparent;
  border: none;
  border-radius: 20%;
  background: linear-gradient(to right, #0096EF, #4caf50);
  -webkit-filter: drop-shadow(0 0 20px rgba(56, 190, 239, 0.632));
}

.rotating-dashed {
  position: absolute;
  margin: 40px auto;
  width: 90px;
  height: 90px;
  overflow: hidden;
  color: #3498db;
  margin: -13px;
}
.rotating-dashed:hover {
  color: #4caf50;
  cursor: pointer;
  transition: all 0.5s;
}
.rotating-dashed .dashing {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.rotating-dashed .dashing:nth-of-type(1) {
  transform: rotate(0deg);
}
.rotating-dashed .dashing:nth-of-type(2) {
  transform: rotate(90deg);
}
.rotating-dashed .dashing:nth-of-type(3) {
  transform: rotate(180deg);
}
.rotating-dashed .dashing:nth-of-type(4) {
  transform: rotate(270deg);
}
.rotating-dashed .dashing i {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  border-bottom: 5px dashed;
  animation: slideDash 2.5s infinite linear;
}
.rotating-dashed strong {
  display: block;
  width: 95px;
  line-height: 90px;
  text-align: center;
  position: absolute;
  font-size: 50px;
  transform: rotate(90deg);
}

@keyframes slideDash {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}
