

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-a-brc-tp:not(.disabled):not(:disabled).active, .btn-brc-tp, .btn-brc-tp:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle), .btn-h-brc-tp:hover, .btn.btn-f-brc-tp:focus, .btn.btn-h-brc-tp:hover {
    border-color: transparent;
}
.btn-outline-blue {
    color: #0d6ce1;
    border-color: #5a9beb;
    background-color: transparent;
}
.btn {
    cursor: pointer;
    position: relative;
    z-index: auto;
    border-radius: .175rem;
    transition: color .15s,background-color .15s,border-color .15s,box-shadow .15s,opacity .15s;
}
.border-2 {
    border-width: 2px!important;
    border-style: solid!important;
    border-color: transparent;
}
.bgc-white {
    background-color: #f2f2f2!important;
}


.text-green-d1 {
    color: #277b5d!important;
}
.letter-spacing {
    letter-spacing: .5px!important;
}
.font-bolder, .text-600 {
    font-weight: 600!important;
}
.text-170 {
    font-size: 1.7em!important;
}

.text-purple-d1 {
    color: #6d62b5!important;
}

.text-primary-d1 {
    color: #276ab4!important;
}
.text-secondary-d1 {
    color: #5f718b!important;
}
.text-180 {
    font-size: 1.8em!important;
}
.text-150 {
    font-size: 1.5em!important;
}
.text-danger-m3 {
    color: #e05858!important;
}
.rotate-45 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.position-l {
    left: 0;
}
.position-b, .position-bc, .position-bl, .position-br, .position-center, .position-l, .position-lc, .position-r, .position-rc, .position-t, .position-tc, .position-tl, .position-tr {
    position: absolute!important;
    display: block;
}
.mt-n475, .my-n475 {
    margin-top: -2.5rem!important;
}
.ml-35, .mx-35 {
    margin-left: 1.25rem!important;
}

.text-dark-l1 {
    color: #56585e!important;
}
.text-90 {
    font-size: .9em!important;
}
.text-left {
    text-align: left!important;
}

.mt-25, .my-25 {
    margin-top: .75rem!important;
}

.text-110 {
    font-size: 1.1em!important;
}

.deleted-text{
text-decoration:line-through;;    
}
