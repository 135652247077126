
ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }
  ul .booking-card {
    position: relative;
    width: 45%;
    display: flex;
    flex: 0 0 45%;
    flex-direction: column;
    margin: 20px;
    margin-bottom: 30px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    text-align: center; 
    color: #0a4870;
    transition: 0.3s;
  }

  .wrapper {
    background: #f0f0f0;
    padding: 20px;
    height: 50vh;
  }
  
 
  ul .booking-card .informations-container {
    background: #f0f0f0;
    transform: translateY(-120px);
    transition: 0.3s;
    height: 0px;

  }
  ul .booking-card .informations-container .title {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.2em;
  }
  ul .booking-card .informations-container .title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    width: 50px;
    margin: auto;
    background: #0a4870;
  }
  ul .booking-card .informations-container .price {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  ul .booking-card .informations-container .price .icon {
    margin-right: 10px;
  }
  ul .booking-card .informations-container .more-information {
    opacity: 0;
    transition: 0.3s;
  }
  ul .booking-card .informations-container .more-information .info-and-date-container {
    display: flex;
  }
  ul .booking-card .informations-container .more-information .info-and-date-container .box {
    flex: 1 0;
    padding: 15px;
    margin-top: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: white;
    font-weight: bold;
    font-size: 0.9em;
  }
  ul .booking-card .informations-container .more-information .info-and-date-container .box .icon {
    margin-bottom: 5px;
  }
  ul .booking-card .informations-container .more-information .info-and-date-container .box.info {
    color: #ec992c;
    margin-right: 10px;
  }
  ul .booking-card .informations-container .more-information .disclaimer {
    margin-top: 20px;
    font-size: 0.8em;
    color: #7d7d7d;
  }
  ul .booking-card:hover::before {
    background: rgba(10, 72, 112, 0.6);
  }
 
  ul .booking-card:hover .informations-container {
    transform: translateY(-300px);
  }
  ul .booking-card:hover .informations-container .more-information {
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    ul .booking-card::before {
      background: rgba(10, 72, 112, 0.6);
    }
 
    ul .booking-card .informations-container .more-information {
      opacity: 1;
    }
  }