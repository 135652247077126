.tools-scroll {
  overflow-x: scroll;
}

.tools-scroll::-webkit-scrollbar {
  width: 1em;
}
 
.tools-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(239, 239, 240, 0.281);
  border-radius: 20px;
}
 
.tools-scroll::-webkit-scrollbar-thumb {
  background-color:  rgb(1 46 64 / 8%);;
  border-radius: 20px;
  outline: 1px solid transparent;
}

.tray {
  width:250vw;
  overflow-x: hidden;
  max-width:  250vw;
}
/* We provide a no-code drag-and-drop platform that enable businesses to create SMS and Email marketing campaigns quickly without coding  */
.tray-item {
    width: 4rem;
    padding: 1.5em !important;
    height: 4rem;
    color: #f2f2f2;
    vertical-align: middle;
  }

  .fa-a:before {
    font-weight: bold;
    content: 'At';
 }


 

 .alias {cursor: alias;}
 .all-scroll {cursor: all-scroll;}
 .auto {cursor: auto;}
 .cell {cursor: cell;}
 .context-menu {cursor: context-menu;}
 .col-resize {cursor: col-resize;}
 .copy {cursor: copy;}
 .crosshair {cursor: crosshair;}
 .default {cursor: default;}
 .e-resize {cursor: e-resize;}
 .ew-resize {cursor: ew-resize;}
 .grab {cursor: -webkit-grab; cursor: grab;}
 .grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
 .help {cursor: help;}
 .move {cursor: move;}
 .n-resize {cursor: n-resize;}
 .ne-resize {cursor: ne-resize;}
 .nesw-resize {cursor: nesw-resize;}
 .ns-resize {cursor: ns-resize;}
 .nw-resize {cursor: nw-resize;}
 .nwse-resize {cursor: nwse-resize;}
 .no-drop {cursor: no-drop;}
 .none {cursor: none;}
 .not-allowed {cursor: not-allowed;}
 .pointer {cursor: pointer;}
 .progress {cursor: progress;}
 .row-resize {cursor: row-resize;}
 .s-resize {cursor: s-resize;}
 .se-resize {cursor: se-resize;}
 .sw-resize {cursor: sw-resize;}
 .text {cursor: text;}
 .w-resize {cursor: w-resize;}
 .wait {cursor: wait;}
 .zoom-in {cursor: zoom-in;}
 .zoom-out {cursor: zoom-out;}