  .chat {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;
  }
  
  .messages {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  
  .message {
    border-radius: 20px;
    padding: 8px 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
  }

  .main {
    padding: 4px 6px;
    color: #f2f2f2;
    background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  }
  
  .yours {
    align-items: flex-start;
  }
  
  .yours .message {
     
    margin-right: 25%;
    background-color: #eee;
    position: relative;
    bottom: 20px;

  }

  .message .last {
    /* width: 80%; */

  }
  
  .yours .message.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #eee;
    border-bottom-right-radius: 15px;
  }
  .yours .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: #232323;
    border-bottom-right-radius: 10px;
  }

  .addon {
    position: absolute;
    bottom: 0;
    left: 15px;
  }

  #data-sheet {
    width: 100%;
  }

  .table-records{
    overflow-y: hidden;
    overflow-x: scroll;
  }

  @media screen and (max-width: 764px) {
    .d-small-none {
      display: none !important;
    }

    .p-small-0 {
      padding: 0 !important;
  }

  .card-body {
    padding: 0.5em !important;
}
  }

@media screen and (min-width: 360px) {
  .p-show-touch {
    display: visible !important;
  }

}